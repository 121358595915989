import { Controller } from "@hotwired/stimulus"
import debounce from 'debounce';

// Connects to data-controller="live-search"
export default class extends Controller {
  static targets = ['perPage', 'perPageButton', 'sortingFields'];

  initialize() {
    this.filter = debounce(this.filter.bind(this), 300);
  }

  filter(event) {
    // requestSubmit() on event.target will submit the form
    event.target.form.requestSubmit();
  }

  // Updates the per_page hidden field (perPageTarget)
  //   and toggle the active class on the clicked button
  updatePerPage({ target: clickedButton, params: { perPage } }) {
    // Remove the active class from all buttons
    this.perPageButtonTargets.forEach((button) => button.classList.remove('active'));

    // Add the active class to the clicked button
    clickedButton.classList.add('active');

    this.perPageTarget.value = perPage;
  }

  // Ransack "sort_link" helper encapsulates the sorting params in the URL.
  // We need to intercept the click event and update the hidden input fields,
  // so it can work together with the other form params (eg: search criteria, pagination).
  // Otherwise, only the turbo_frame "filtered results" would be updated
  // and the sorting params would be lost.
  // To intercept the clicks, we added
  //   data: { action: "click->live-search#updateSortParameters" }
  // to the links.
  updateSortParameters(event) {
    // Clear any hidden input field under sortingFieldsTarget
    this.sortingFieldsTarget.innerHTML = '';

    // For each sorting parameter in url ([:q][:s]), create a hidden input field
    const sortParams = this.extractSortParams(event.target.href);

    sortParams.forEach((sortParam) => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = 'q[s][]';
      input.value = sortParam;
      this.sortingFieldsTarget.appendChild(input);
    });
  }

  extractSortParams(url) {
    const queryParamsString = url.split('?')[1];
    const searchParams = new URLSearchParams(queryParamsString);
    const sortParams = searchParams.getAll('q[s][]');

    // Returns in the format: ["name asc", "id desc"]
    return sortParams;
  }
}
